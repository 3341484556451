<template>
  <div class="container">
    <div class="card border-0 shadow-none">
      <div class="card-body">
        <h3 class="fw-bold text-success">Reporting</h3>

        <div style="display: flex; justify-content: space-between">
          <router-link to="/reporting/retreat-bookings" class="btn btn-success"
            ><i class="far fa-calendar me-2"></i>Retreat
            Bookings</router-link
          >

          <router-link
            to="/reporting/accounts-by-date"
            class="btn btn-success"
            ><i class="far fa-users me-2"></i>Accounts by
            Date</router-link
          >

          <router-link
            to="/reporting/stripe-booking-payments"
            class="btn btn-success"
            ><i class="fab fa-cc-stripe me-2"></i>Stripe Payments
            </router-link
          >

          <router-link
            to="/reporting/debtors"
            class="btn btn-success"
            ><i class="far fa-credit-card me-2"></i>Debtors
            </router-link
          >

          <router-link
            to="/reporting/enquiries-by-traffic-source"
            class="btn btn-success"
            ><i class="far fa-envelope me-2"></i>Enquiries by Traffic Source
            </router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>